import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'

const service = axios.create({
  baseURL: 'https://kyc.ecaasospoc.com',
  timeout: 15000
})

service.defaults.withCredentials = true
// Request interceptors
service.interceptors.request.use(
  (config) => {
    if (UserModule.oauth.accessToken) {
      config.headers.Authorization = `Bearer ${UserModule.oauth.accessToken}`
      // config.headers['x-hasura-admin-secret'] = 'myadminsecretkey'
    }
    // config.headers.__tenant = Constant.TENANT_ID
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const { data } = response
    const { errors } = data
    if (errors && errors.length > 0) {
      const [error] = errors
      const { extensions } = error
      const { code } = extensions
      if (code && code === 'invalid-jwt') {
        MessageBox.confirm(
          '登录状态已失效，请重新登录',
          '登出',
          {
            confirmButtonText: '重新登录',
            type: 'warning',
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showCancelButton: false,
            showClose: false
          }
        ).then(async() => {
          await UserService.logout(false)
          location.reload() // To prevent bugs from vue-router
        })
      }
    }
    return response
  },
  (error) => {
    const status = error?.response?.status
    if (status === 401) {
      MessageBox.confirm(
        '登录状态已失效，请重新登录',
        '登出',
        {
          confirmButtonText: '重新登录',
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showCancelButton: false,
          showClose: false
        }
      ).then(async() => {
        await UserService.logout(false)
        location.reload() // To prevent bugs from vue-router
      })
    } else if (status === 403) {
      MessageBox.confirm(
        '该权限未获取，请联系系统管理员',
        '无权限',
        {
          confirmButtonText: '重新登录',
          type: 'warning',
          closeOnClickModal: true,
          closeOnPressEscape: true,
          showCancelButton: true,
          showClose: false
        }
      ).then(async() => {
        await UserService.logout(false)
        location.reload() // To prevent bugs from vue-router
      })
    } else {
      Message({
        message: error?.response?.data?.error.message ?? error?.message,
        type: 'error',
        duration: 5 * 1000
      })
      return error
    }
    return Promise.reject(error)
  }
)

export default service

export function fetchGraphQL(
  operationsDoc: string,
  operationName: string,
  variables: Record<string, any>
) {
  return service('https://mailhog.ecaasospoc.com/v1/graphql', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: {
      query: operationsDoc,
      variables,
      operationName
    }
  })
}
// {
//   "errors": [
//   {
//     "message": "Could not verify JWT: JWTExpired",
//     "extensions": {
//       "path": "$",
//       "code": "invalid-jwt"
//     }
//   }
// ]
// }
