/**
 * @Author: siyukok
 * @Date: 2022/1/28 8:54 PM
 * @Version 1.0
 */
export default class Routes {
  static Login = '/login'
  static Callback = '/callback'
  static NotMatch = '/404'
  static Root = '/'

  static Center = '/center'

  // 默认/wecom为首页
  // static Home = Routes.CustomerMyClues

  // 默认/customer为首页
  static Home = `${Routes.Center}/index`
}
