import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'
import Routes from '@/constant/routes'

Vue.use(Router)

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: Routes.Login,
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: {
      hidden: true,
      title: '登录'
    }
  },
  {
    path: Routes.Callback,
    component: () => import(/* webpackChunkName: "login" */ '@/views/callback/index.vue'),
    meta: {
      hidden: true
    }
  },
  {
    path: Routes.NotMatch,
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: {
      hidden: true,
      title: '404'
    }
  },
  {
    path: Routes.Root,
    component: Layout,
    redirect: Routes.Center
  },
  {
    path: Routes.Center,
    component: Layout,
    redirect: Routes.Home,
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/center/index.vue'),
        name: 'Center',
        meta: {
          title: '交易监管平台',
          icon: 'center',
          noCache: true
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes: RouteConfig[] = []
export default new Router({
  // mode: 'history', // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})
