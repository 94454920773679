
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
    query MyQuery {
      exchange_order_summary(order_by: {id: desc}) {
        id
        existence_tx_hash
        existence_block_hash
        rwa_token_id
        rwa_token_name
        rwa_token_type
        rwa_token_issuer
        buyer_address
        seller_address
        completed_at
        ai_transfer_risk_index
        check_status
      }
    }
  `

function fetchExchangeQuery() {
  return fetchGraphQL(operation, 'MyQuery', {})
}

export default fetchExchangeQuery
