/**
 * @Author: siyukok
 * @Date: 2022/1/28 8:45 PM
 * @Version 1.0
 */
/* eslint-disable quote-props */
import ValidateUtil from '@/utils/validate'

export default class Constant {
  static CLIENT_ID = 'DpBackend_App'
  static TENANT_ID = process.env.VUE_APP_TENANT_ID
  // static TENANT_ID = ''
  static APP_HOST = process.env.VUE_APP_HOST
  // static MINI_PROGRAM_APP_ID = 'wxb67dda233357c55a'
  // 有赞商城小程序
  static MINI_PROGRAM_APP_ID = process.env.VUE_APP_MINI_PROGRAM_APP_ID ?? ''
  static GRANT_TYPES = {
    Code: 'authorization_code',
    Password: 'password',
    RefreshToken: 'refresh_token'
  }

  static checkStatus = {
    checking: 'checking',
    pass: 'pass',
    fail: 'fail',
    ready: 'ready'
  }

  static WECOM_ENTRY_TYPES = {
    // 从联系人详情进入
    ContactProfile: 'contact_profile',
    // 从单聊会话的工具栏进入
    SingleChatTools: 'single_chat_tools',
    // 从群聊会话的工具栏进入
    GroupChatTools: 'group_chat_tools',
    // 从聊天附件栏进入
    ChatAttachment: 'chat_attachment',
    // 除以上场景之外进入，例如工作台，聊天会话等
    Normal: 'normal'
  }

  static DictKeys = {
    Contact: '联系方式',
    FollowClueWays: '客户跟进方式',
    ClueInvalidCauses: '失效原因',
    ClueSucceedCauses: '成单原因',
    ClueFailCauses: '输单原因',
    ClueReturnCauses: '返还原因'
  }

  static ClueSourceType = {
    OceanEngine: {
      label: '巨量引擎',
      value: 'OceanEngine'
    },
    WeChatCustomer: {
      label: '企微客户',
      value: 'WeChatCustomer'
    },
    Customer: {
      label: '客户',
      value: 'Customer'
    },
    BackendNew: {
      label: '后台创建',
      value: 'BackendNew'
    },
    BackendNewByChannel: {
      label: '后台新建-代供应商提供',
      value: 'BackendNewByChannel'
    },
    BackendWeChatApplet: {
      label: '微信小程序',
      value: 'BackendWeChatApplet'
    }
  }

  static Warnings = {
    InvalidData: '数据初始化失败，请刷新重试',
    CreateClueInvalidContact: '至少需添加一个联系方式',
    CreateClueInvalidName: '请输入客户姓名',
    CreateClueInvalidProvider: '请选择供应商'
  }

  static formRules = {
    '手机号': ValidateUtil.validateMobile,
    '微信': ValidateUtil.validateWechat,
    QQ: ValidateUtil.validateQQ,
    '邮箱': ValidateUtil.validateEmail,
    '企微好友': ValidateUtil.validateWecom
  }

  static ClueStatus = {
    WaitAllocate: {
      label: '待分配',
      value: 'WaitAllocate'
    },
    Following: {
      label: '跟进中',
      value: 'Following'
    },
    WaitFollow: {
      label: '待跟进',
      value: 'WaitFollow'
    },
    OrderSucceeded: {
      label: '已成单',
      value: 'OrderSucceeded'
    },
    OrderFailed: {
      label: '已输单',
      value: 'OrderFailed'
    },
    Invalid: {
      label: '已失效',
      value: 'Invalid'
    }
  }

  static SignStatus = {
    WaitSign: {
      label: '待签名',
      value: 'WaitSign'
    },
    Signed: {
      label: '已签名',
      value: 'Signed'
    }
  }

  static OrderStatus = {
    WaitPay: {
      label: '待支付',
      value: 'WaitPay'
    },
    Paying: {
      label: '付款中',
      value: 'Paying'
    },
    Paid: {
      label: '已支付',
      value: 'Paid'
    },
    Refunded: {
      label: '已退款',
      value: 'Refunded'
    },
    Cancel: {
      label: '已取消',
      value: 'Cancel'
    },
    Close: {
      label: '已关闭',
      value: 'Close'
    }
  }

  static PayType = {
    Weixin: {
      label: '微信支付',
      value: 'Weixin'
    },
    Alipay: {
      label: '支付宝支付',
      value: 'Alipay'
    },
    Iap: {
      label: '苹果支付',
      value: 'Iap'
    },
    OfflinePay: {
      label: '线下支付',
      value: 'OfflinePay'
    },
    OutPlatformPay: {
      label: '外部平台支付',
      value: 'OutPlatformPay'
    }
  }

  static GenderType = {
    Unknown: {
      label: '未知',
      value: 'Unknown'
    },
    Man: {
      label: '男',
      value: 'Man'
    },
    Woman: {
      label: '女',
      value: 'Woman'
    }
  }

  static ProductType = {
    Virtual: {
      label: '虚拟商品',
      value: 'Virtual'
    },
    Physical: {
      label: '实物商品',
      value: 'Physical'
    }
  }

  static ProductStatus = {
    Draft: {
      label: '草稿',
      value: 'Draft'
    },
    InSale: {
      label: '销售中',
      value: 'InSale'
    },
    HaltSale: {
      label: '已停售',
      value: 'HaltSale'
    }
  }

  static ClueKeyRoleType = {
    Creator: {
      label: '创建者',
      value: 'Creator'
    },
    Follower: {
      label: '跟进者',
      value: 'Follower'
    },
    Winner: {
      label: '成单者',
      value: 'Winner'
    }
  }

  static CustomerKeyRoleType = {
    Creator: {
      label: '创建者',
      value: 'Creator'
    }
  }

  static OrderKeyRoleType = {
    Creator: {
      label: '创建者',
      value: 'Creator'
    },
    Salesman: {
      label: '销售者',
      value: 'Salesman'
    }
  }

  static OrderType = {
    Buy: {
      label: '购买',
      value: 'Buy'
    },
    Recharge: {
      label: '充值',
      value: 'Recharge'
    }
  }

  static ClueDateRangeType = {
    AllocatedAt: {
      label: '分配时间',
      value: 'AllocatedAt'
    },
    FollowedAt: {
      label: '最后跟进时间',
      value: 'FollowedAt'
    },
    CreatedAt: {
      label: '创建时间',
      value: 'CreatedAt'
    }
  }

  static OrderDateRangeType = {
    CreatedAt: {
      label: '下单时间',
      value: 'CreatedAt'
    },
    PaidAt: {
      label: '完款时间',
      value: 'PaidAt'
    }
  }

  static ChannelDateRangeType = {
    CreatedAt: {
      label: '入库时间',
      value: 'CreatedAt'
    },
    PaidAt: {
      label: '签名时间',
      value: 'PaidAt'
    }
  }

  static WecomSourceType = {
    Unknown: {
      label: '未知来源',
      value: 'Unknown'
    },
    ScanQrCode: {
      label: '扫描二维码',
      value: 'ScanQrCode'
    },
    SearchMobile: {
      label: '搜索手机号',
      value: 'SearchMobile'
    },
    CardShare: {
      label: '名片分享',
      value: 'CardShare'
    },
    GroupChat: {
      label: '群聊',
      value: 'GroupChat'
    },
    MobileAddressBook: {
      label: '手机通讯录',
      value: 'MobileAddressBook'
    },
    WechatContact: {
      label: '微信联系人',
      value: 'WechatContact'
    },
    WechatAddFriend: {
      label: '来自微信的添加好友申请',
      value: 'WechatAddFriend'
    },
    CustomerServiceStaff: {
      label: '安装第三方应用时自动添加的客服人员',
      value: 'CustomerServiceStaff'
    },
    SearchMailbox: {
      label: '搜索邮箱',
      value: 'SearchMailbox'
    },
    WechatVideoAdd: {
      label: '视频号主页添加',
      value: 'SearchMailbox'
    },
    InternalShare: {
      label: '内部成员共享',
      value: 'SearchMailbox'
    },
    Distribution: {
      label: '管理员/负责人分配',
      value: 'SearchMailbox'
    }
  }

  static Contracts = {
    S01001: {
      code: 'S01001',
      name: '总部流量 总部跟进 总部成交',
      hasProvider: false,
      hasOrganizer: false
    },
    S02001: {
      code: 'S02001',
      name: '总部流量 总部跟进 外场成交',
      hasProvider: false,
      hasOrganizer: true
    },
    S03001: {
      code: 'S03001',
      name: '供应商流量 总部跟进 总部成交',
      hasProvider: true,
      hasOrganizer: false
    },
    S04001: {
      code: 'S04001',
      name: '供应商流量 总部跟进 外场成交',
      hasProvider: true,
      hasOrganizer: true
    }
  }

  static OrderReviewStatus = {
    Wait: 'Wait',
    Reviewed: 'Reviewed',
    Fail: 'Fail'
  }

  static ContractStatus = {
    Default: {
      label: '无需上链',
      value: 'Default'
    },
    WaitPush: {
      label: '待上链',
      value: 'WaitPush'
    },
    PushedAndWaitReview: {
      label: '待审核',
      value: 'PushedAndWaitReview'
    },
    Reviewed: {
      label: '已审核',
      value: 'Reviewed'
    },
    PushFail: {
      label: '待上链',
      value: 'PushFail'
    },
    ReviewFail: {
      label: '待审核',
      value: 'ReviewFail'
    }
  }

  static questionStatus = {
    Draft: {
      code: 'Draft',
      name: '草稿'
    },
    Using: {
      code: 'Using',
      name: '上架'
    },
    Cancel: {
      code: 'Cancel',
      name: '下架'
    }
  }

  static evaluationStatus = {
    Draft: {
      code: 'Draft',
      name: '草稿'
    },
    Using: {
      code: 'Using',
      name: '上架'
    },
    Cancel: {
      code: 'Cancel',
      name: '下架'
    }
  }
}

export enum TagType {
  Customer = 'Customer',
  Clue = 'Clue'
}

export enum RecordType {
  Payment = 'Payment',
  Refund = 'Refund'
}

export const dateRangePickerOptions = {
  shortcuts: [{
    text: '最近一周',
    onClick(picker: any) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近一个月',
    onClick(picker: any) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      picker.$emit('pick', [start, end])
    }
  }, {
    text: '最近三个月',
    onClick(picker: any) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      picker.$emit('pick', [start, end])
    }
  }]
}
