import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { PermissionModule } from '@/store/modules/permission'
import { UserModule } from '@/store/modules/user'
import Routes from '@/constant/routes'

NProgress.configure({ showSpinner: false })

const whiteList = [Routes.Login, Routes.Callback]

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()

  // TODO refine
  PermissionModule.GenerateRoutes([])
  // Determine whether the user has logged in
  if (UserModule.oauth.accessToken) {
    if (to.path === Routes.Login) {
      // If is logged in, redirect to the home page
      next({ path: Routes.Home })
      NProgress.done()
    } else {
      next()
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to.meta?.title
})
