/**
 * @Author: siyukok
 * @Date: 2022/1/18 3:28 PM
 * @Version 1.0
 */
export default class ApiConst {
  /* 微信 */
  static GET_WECOM_SIGN = 'api/app/jsapi-ticket/sign-corp'
  static GET_WECOM_APP_SIGN = 'api/app/jsapi-ticket/sign-app'
  static WECOM_AUTH = 'api/app/corp-user/auth'
  static DEPARTMENT = 'api/app/department'
  static CORP_USER = 'api/app/corp-user'
  static CORP_USER_SEARCH = 'api/app/corp-user/search'
  static CORP_USER_INFO = 'api/app/corp-user/info'
  static CORP_USER_SYNC = (departmentId: number) => `api/app/corp-user/refresh/${departmentId}`
  static WECOM_CUSTOMER_LIST = 'api/app/we-chat-customer'
  // 企微客户详情
  static WECOM_CUSTOMER_INFO = (userId: string) => `api/app/we-chat-customer/info/${userId}`

  /* 用户 */
  static CONNECT_TOKEN = 'connect/token'
  static LOGOUT = 'api/account/logout'
  static MY_PROFILE = 'api/account/my-profile'
  static USER_ROLES = (userId: string) => `api/identity/users/${userId}/roles`

  /* 客户中心 */
  static CLUE = 'api/app/clue'
  static MY_CLUES = 'api/app/clue/me'
  static CLUES_CREATED_BY_ME = 'api/app/clue/me-created'
  static CLUE_WAIT = 'api/app/clue/wait'
  static CLUE_CUSTOMER = 'api/app/clue/customer'
  static CLUE_DETAILS = (clueId: string) => `api/app/clue/${clueId}`
  // 上报查看线索手机号
  static CLUE_LOOK_UP = (clueId: string) => `api/app/clue/${clueId}/look-up`
  static CLUE_REMARK_EDIT = (clueId: string) => `api/app/clue/${clueId}/edit`
  static CLUE_INVALID = (clueId: string) => `api/app/clue/${clueId}/invalid`
  static CLUE_FOLLOW = (clueId: string) => `api/app/clue/${clueId}/follow`
  static CLUE_ALLOCATE = 'api/app/clue/allocated'
  static CLUE_ORDER_SUCCEED = (clueId: string) => `api/app/clue/${clueId}/order-succeed`
  static CLUE_ORDER_FAIL = (clueId: string) => `api/app/clue/${clueId}/order-fail`
  static CLUE_RETURN = (clueId: string) => `api/app/clue/${clueId}/return`
  static CLUE_SET_TAG = (clueId: string) => `api/app/clue/${clueId}/set-tag`
  static CUSTOMER = 'api/app/customer'
  static CUSTOMER_DETAIL = (id: string) => `api/app/customer/${id}`
  static CUSTOMER_SEARCH = 'api/app/customer/search'
  static CUSTOMER_EDIT_NAME = (id: string) => `api/app/customer/${id}/edit-name`
  static CUSTOMER_EDIT_NICK = (id: string) => `api/app/customer/${id}/edit-nick`
  static CUSTOMER_EDIT_BIRTHDAY = (id: string) => `api/app/customer/${id}/edit-birthday`
  static CUSTOMER_EDIT_GENDER = (id: string) => `api/app/customer/${id}/edit-gender`
  static CUSTOMER_EDIT_REMARK = (id: string) => `api/app/customer/${id}/edit-remark`
  static CUSTOMER_EDIT_CONTACT_WAY = (id: string) => `api/app/customer/${id}/edit-contact-way`
  static CUSTOMER_ADD_CONTACT_WAY = (id: string) => `api/app/customer/${id}/contact-way`
  // 客户身份
  static CUSTOM_IDENTITY_INFO = (id: string) => `api/app/customer-identity/${id}`
  static CUSTOM_IDENTITY = 'api/app/customer-identity'
  static CUSTOM_IDENTITY_EDIT = (id: string) => `api/app/customer-identity/${id}/edit`
  static CUSTOM_IDENTITY_ENABLE = (id: string) => `api/app/customer-identity/${id}/enable`

  /* 营销中心 */
  static PRODUCT_SEARCH = 'api/app/product/search'
  // 订单
  static ORDER = 'api/app/order'
  static ORDER_CUSTOMER = 'api/app/order/customer'
  static ORDER_SEARCH = 'api/app/order/search'
  static ORDER_DETAIL = (id: string) => `api/app/order/${id}`
  static ORDER_PAY_RECORD = (id: string) => `api/app/order/${id}/pay-record`
  static ORDER_PAYMENT_RECORD = (id: string) => `api/app/order/${id}/payment-record`
  static ORDER_REFUND_RECORD = (id: string) => `api/app/order/${id}/refund-record`

  /* 系统 */
  // 某个角色的权限
  static PERMISSION_MANAGEMENT = 'api/permission-management/permissions'
  // 可分配角色列表
  static ASSIGNABLE_ROLES = 'api/identity/users/assignable-roles'
  static TAG_EDIT = (id: string) => `api/app/tag/${id}/edit`
  static TAG_DELETE = (id: string) => `api/app/tag/${id}/del`
  static TAG_SET = 'api/app/tag/set-tag'
  // 获取全部标签组及标签
  static TAG_GROUP_ALL = 'api/app/tag-group/all'
  static TAG_GROUP_ALL_READ = 'api/app/tag-group/read-only-all'
  static TAG_GROUP = 'api/app/tag-group'
  static TAG_GROUP_BY_ID = (id: string) => `api/app/tag-group/${id}`
  static TAG_GROUP_EDIT = (id: string) => `api/app/tag-group/${id}/edit`
  static TAG_GROUP_DELETE = (id: string) => `api/app/tag-group/${id}/del`
  static TAG_GROUP_TAGS = (id: string) => `api/app/tag-group/${id}/tag`
  // 获取字典
  static DICT_GROUPS = 'api/app/dict'
  static UPLOAD = 'api/app/upload/image'
  // 营销数据
  static DATA_MARKETING = 'api/app/statistic/info'

  // 资产确权稽查
  static CHECK_ASSETS_RIGHT = 'api/exchange/check-asset-right'

  // 底层资产稽查
  static CHECK_BOTTOM_ASSETS = 'api/exchange/check-bottom-assets'

  // 存证信息稽查
  static CHECK_EXISTENCE_PROOF = 'api/exchange/check-existence-proof'

  // 交易信息稽查
  static CHECK_ORDER_PROOF = 'api/exchange/check-order-proof'

  // 买家信息稽查
  static CHECK_SELLER_IDENTITY_PROOF = 'api/exchange/check-seller-identity-proof'

  // 卖家信息稽查
  static CHECK_BUYER_IDENTITY_PROOF = 'api/exchange/check-buyer-identity-proof'

  // hex转utf8
  static HEX_TO_UTF8 = 'api/eth/hex-to-utf8'
}
