import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { RouteConfig } from 'vue-router'
import { asyncRoutes, constantRoutes } from '@/router'
import store from '@/store'
import { IPermissionData } from '@/api/types'
import BooleanUtil from '@/utils/boolean'

const hasPermission = (permissions: IPermissionData[], route: RouteConfig) => {
  if (route.meta && route.meta.permissions && Array.isArray(route.meta.permissions)) {
    let result = false
    try {
      route.meta.permissions.forEach((p: string) => {
        if (BooleanUtil.filterBool(permissions.find(permission => permission.name === p)?.isGranted)) {
          result = true
          throw new Error('结束循环，避免资源浪费')
        }
      })
    } catch (_) {
    }
    return result
  }
  if (route.meta && route.meta.permission) {
    return BooleanUtil.filterBool(permissions.find(permission => route.meta?.permission === permission.name)?.isGranted)
  } else {
    return true
  }
}

export const filterAsyncRoutes = (routes: RouteConfig[], permissions: IPermissionData[]) => {
  const res: RouteConfig[] = []
  routes.forEach(route => {
    const r = { ...route }
    if (hasPermission(permissions, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, permissions)
      }
      res.push(r)
    }
  })
  return res
}

export interface IPermissionState {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
}

@Module({
  dynamic: true,
  store,
  name: 'permission'
})
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = []
  public dynamicRoutes: RouteConfig[] = []

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes)
    this.dynamicRoutes = routes
  }

  @Action
  public GenerateRoutes(permissions: IPermissionData[]) {
    const routes = filterAsyncRoutes(asyncRoutes, permissions)
    this.SET_ROUTES(routes)
  }
}

export const PermissionModule = getModule(Permission)
