import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { getSidebarStatus, setSidebarStatus } from '@/utils/cookies'
import store from '@/store'
import { IDictGroupData } from '@/api/types'
import UserStorage from "@/storages/user";

export enum DeviceType {
  Mobile,
  Desktop,
}

export interface ICreateClueDialogInfo {
  show?: boolean
  customerId: string
  source: string
  customerName?: string
  contactWayDictIds: string[]
  contactWays: string[]
  contactWayTitles: string[]
}

export interface IAppState {
  device: DeviceType
  sidebar: {
    opened: boolean
    withoutAnimation: boolean
  }
  createClueDialog: ICreateClueDialogInfo
  dictGroups: IDictGroupData[]
}

@Module({
  dynamic: true,
  store,
  name: 'app'
})
class App extends VuexModule implements IAppState {
  public sidebar = {
    opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false
  }

  public total = {
    keyTotal: UserStorage.total
  }

  public createClueDialog: ICreateClueDialogInfo = {
    show: false,
    customerId: '',
    source: '',
    customerName: '',
    contactWayDictIds: [],
    contactWays: [],
    contactWayTitles: []
  }

  public device = DeviceType.Desktop

  dictGroups: IDictGroupData[] = []

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = !this.sidebar.opened
    this.sidebar.withoutAnimation = withoutAnimation
    if (this.sidebar.opened) {
      setSidebarStatus('opened')
    } else {
      setSidebarStatus('closed')
    }
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false
    this.sidebar.withoutAnimation = withoutAnimation
    setSidebarStatus('closed')
  }

  @Mutation
  private OPEN_CREATE_CLUE_DIALOG(clueDialog: ICreateClueDialogInfo) {
    this.createClueDialog = {
      ...clueDialog,
      show: true
    }
  }

  @Mutation
  private CLOSE_CREATE_CLUE_DIALOG() {
    this.createClueDialog = {
      contactWayDictIds: [],
      contactWayTitles: [],
      contactWays: [],
      show: false,
      customerId: '',
      source: ''
    }
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device
  }

  @Mutation
  private SET_DICT_GROUPS(dictGroups: IDictGroupData[]) {
    this.dictGroups = dictGroups
  }

  @Action
  public ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation)
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation)
  }

  @Action
  public OpenCreateClueDialog(clueDialog: ICreateClueDialogInfo) {
    this.OPEN_CREATE_CLUE_DIALOG({
      ...clueDialog
    })
  }

  @Action
  public CloseCreateClueDialog() {
    this.CLOSE_CREATE_CLUE_DIALOG()
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device)
  }

  @Action
  public SetDictGroups(dictGroups: IDictGroupData[]) {
    this.SET_DICT_GROUPS(dictGroups)
  }
}

export const AppModule = getModule(App)
