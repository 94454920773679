/**
 * @Author: siyukok
 * @Date: 2022/3/8 5:27 PM
 * @Version 1.0
 */
export default class DateUtil {
  // YYYY-MM-DD
  static toDate(format: string): Date {
    return new Date(format)
  }

  /**
   * 对日期进行格式化， 和C#大致一致 默认yyyy-MM-dd HH:mm:ss
   * 可不带参数 一个日期参数 或一个格式化参数
   * @param date 要格式化的日期
   * @param format 进行格式化的模式字符串
   *     支持的模式字母有：
   *     y:年,
   *     M:年中的月份(1-12),
   *     d:月份中的天(1-31),
   *     H:小时(0-23),
   *     h:小时(0-11),
   *     m:分(0-59),
   *     s:秒(0-59),
   *     f:毫秒(0-999),
   *     q:季度(1-4)
   * @return String
   */
  static dateFormat(date?: any, format?: string): string {
    // 无参数
    if (!date && !format) {
      date = new Date()
      format = 'yyyy-MM-dd HH:mm:ss'
    } else if (typeof (date) === 'string') {
      // 无日期
      format = date
      date = new Date()
    } else if (format === undefined) {
      // 无格式化参数
      format = 'yyyy-MM-dd HH:mm:ss'
    }
    const map = {
      y: date.getFullYear() + '', // 年份
      M: date.getMonth() + 1 + '', // 月份
      d: date.getDate() + '', // 日
      H: date.getHours(), // 小时 24
      m: date.getMinutes() + '', // 分
      s: date.getSeconds() + '', // 秒
      q: Math.floor((date.getMonth() + 3) / 3) + '', // 季度
      f: date.getMilliseconds() + '', // 毫秒
      h: ''
    }
    // 小时 12
    if (map.H > 12) {
      map.h = map.H - 12 + ''
    } else {
      map.h = map.H + ''
    }
    map.H += ''

    const reg = 'yMdHhmsqf'
    let all = ''
    let str = ''
    for (let i = 0, n = 0; i < reg.length; i++) {
      n = format.indexOf(reg[i])
      if (n < 0) {
        continue
      }
      all = ''
      for (; n < format.length; n++) {
        if (format[n] !== reg[i]) {
          break
        }
        all += reg[i]
      }
      if (all.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tempStr = map[reg[i]]
        if (all.length === tempStr.length) {
          str = tempStr
        } else if (all.length > tempStr.length) {
          if (reg[i] === 'f') {
            str = tempStr + this.charString('0', all.length - tempStr.length)
          } else {
            str = this.charString('0', all.length - tempStr.length) + tempStr
          }
        } else {
          switch (reg[i]) {
            case 'y':
              str = tempStr.substr(tempStr.length - all.length)
              break
            case 'f':
              str = tempStr.substr(0, all.length)
              break
            default:
              str = tempStr
              break
          }
        }
        format = format.replace(all, str)
      }
    }
    return format
  }

  /**
   * 返回字符串 为n个char构成
   * @param char 重复的字符
   * @param count 次数
   * @return String
   * @author adswads@gmail.com
   */
  static charString(char: string, count: number): string {
    let str = ''
    while (count--) {
      str += char
    }
    return str
  }

  static generateDateData(startYear = 1950, endYear = 2022) {
    const years = []
    const month31 = []
    for (let day = 1; day <= 31; day++) {
      month31.push({
        label: day
      })
    }
    const month30 = month31.concat()
    month30.splice(month31.length - 1, 1)
    const month29 = month30.concat()
    month29.splice(month30.length - 1, 1)
    const month28 = month29.concat()
    month28.splice(month29.length - 1, 1)
    const list31 = [1, 3, 5, 7, 8, 10, 12]
    for (let year = startYear; year <= endYear; year++) {
      const months = []
      for (let month = 1; month <= 12; month++) {
        if (month === 2) {
          months.push({
            label: month,
            children: DateUtil.isLeapYear(year) ? month29 : month28
          })
        } else if (list31.includes(month)) {
          months.push({
            label: month,
            children: month31
          })
        } else {
          months.push({
            label: month,
            children: month30
          })
        }
      }
      years.push({
        label: year,
        children: months
      })
    }
    return years
  }

  static isLeapYear(year: number) {
    if (year % 100 === 0) {
      return year % 400 === 0
    }
    return year % 4 === 0
  }
}
